<template>
    <dashboard-layout-form>
        <template slot="actions">
            <v-btn color="primary" class="rounded-0" @click="submit()" v-if="this.$route.params.id">Updaten</v-btn>
            <v-btn color="primary" class="rounded-0" @click="submit()" v-else>Opslaan</v-btn>
        </template>
        <v-container>
            <v-card class="pa-8">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="pa-0 ma-0">Inschrijvers:</v-card-title>
                    </v-col>
                </v-row>
                <v-form>
                    <v-row>
                        <v-col cols="4">
                        <v-text-field
                            v-model="naam"
                            :counter="50"
                            label="Naam inschrijver"
                            clearable
                            required
                            :rules="[v => !!v || 'Dit veld is verplicht']"
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            clearable
                            auto-grow
                            label="E-mailadres"
                            email
                            :counter="255"
                            required
                            :rules="[v => !!v || 'Dit veld is verplicht']"
                        ></v-text-field>
                                    <v-checkbox
                                        v-model="active"
                                        label="Actief"
                                        outlined
                                        clearable
                                    ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-container>
    </dashboard-layout-form>
</template>

<script>
export default {
    name: 'Inschrijvers',

    data () {
        return {
            tab: null,

            naam: '',
            email: '',
            active: true,
            verified: false,
            token: '',

            items: ['Inschrijver Informatie', 'Beheer']
        }
    },
    methods: {
        async submit() {
            this.loading = true;

            if (this.$route.params.id){
                try {
                    // Edit inschrijver
                    await this.$axios.post(`inschrijvers/${this.$route.params.id}`, {
                        _method: 'PATCH',
                        naam: this.naam,
                        email: this.email,

                        active: this.active,
                        verified: this.verified,
                        token: this.token,
                    });
                    await this.$router.push({name: 'Inschrijvers'});

                } catch(e) {
                    if (e.response.status === 422) {
                        if (e.response.data.error) {
                            await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                        }
                    }
                } finally {
                    this.loading = false;
                }
            }else {
                // New Inschrijver
                try {
                    await this.$axios.post('inschrijvers', {
                        naam: this.naam,
                        email: this.email,

                        active: this.active,
                        verified: this.verified,
                        token: this.token,
                    });
                    await this.$router.push({name: 'Inschrijvers'});

                } catch(e) {
                    if (e.response.status === 422) {
                        if (e.response.data.error) {
                            await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                        }
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async load(id) {
            let resp = await window.axios.get(`inschrijvers/${id}`);
            let data = resp.data.data;

            this.naam = data.naam;
            this.email = data.email;
            this.active = data.active;
            this.verified = data.verified;
            this.token = data.token;
        }
    },
    async mounted() {
        if (this.$route.params.id)
            await this.load(this.$route.params.id);
    }
}
</script>
