import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import User from "../views/app/Users";

import Inschrijvers from "../views/app/inschrijvers/Inschrijvers";
import NewInschrijver from "../views/app/inschrijvers/NewInschrijvers";
import Templates from "../views/app/mailtemplates/Templates";
import NewTemplate from "../views/app/mailtemplates/NewTemplate";
import MailPlanners from "../views/app/mailplanner/MailPlanners";
import NewMailPlanner from "../views/app/mailplanner/NewMailplanner";
import SendBatches from "../views/app/mailplanner/SendBatches";
import MailSettings from "../views/app/settings/MailSettings";

Vue.use(VueRouter)

const routes = [
    {
        // Main Dashboard
        path: '/',
        name: 'Home',
        meta: {
            auth: true
        },
        component: Home
    },

    // UsersOverview Route
    {
        path: '/users',
        name: 'Users',
        meta: {
            auth: true
        },
        component: User
    },

    // Inschrijver Routes
    {
        path: '/inschrijvers/overview',
        name: 'Inschrijvers',
        meta: {
            auth: true
        },
        component: Inschrijvers
    },
    {
        path: '/inschrijvers/new',
        name: 'NewInschrijver',
        meta: {
            auth: true
        },
        component: NewInschrijver
    },
    {
        path: '/inschrijvers/:id',
        name: 'EditInschrijver',
        meta: {
            auth: true
        },
        component: NewInschrijver
    },

    // Mail Template Routes
    {
        path: '/templates/overview',
        name: 'Templates',
        meta: {
            auth: true
        },
        component: Templates
    },
    {
        path: '/templates/new',
        name: 'NewTemplate',
        meta: {
            auth: true
        },
        component: NewTemplate
    },
    {
        path: '/templates/:id',
        name: 'EditTemplate',
        meta: {
            auth: true
        },
        component: NewTemplate
    },


    // Mail Planner Routes
    {
        path: '/planners/overview',
        name: 'Planners',
        meta: {
            auth: true
        },
        component: MailPlanners
    },
    {
        path: '/planners/new',
        name: 'NewPlanner',
        meta: {
            auth: true
        },
        component: NewMailPlanner
    },
    {
        path: '/planners/:id',
        name: 'EditPlanner',
        meta: {
            auth: true
        },
        component: NewMailPlanner
    },
    {
        path: '/settings/smtp',
        name: 'MailSettings',
        meta: {
            auth: true
        },
        component: MailSettings
    },

    // Send emails beheer
    {
        path: '/batches/overview',
        name: 'Batches',
        meta: {
            auth: true
        },
        component: SendBatches
    },


    // Public Login Route
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/front/Login')
    },



]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }
    next()
})

export default router
