import {MakeExtension, VuetifyIcon} from "tiptap-vuetify";
import MewsDialog from "../components/UploadDialog";
import Vue from "vue";

export default
MakeExtension({
    nativeExtensionName: "upload",
    icons: {
        mdi: new VuetifyIcon("mdi-upload"),
        md: new VuetifyIcon("mdi-upload"),
        fa: new VuetifyIcon("mdi-upload"),
        mdiSvg: new VuetifyIcon("mdi-upload")
    },
    tooltip: "Upload bestand",
    isActive: () => false,
    onClick: (c) => {
        let dlg = Vue.extend(MewsDialog);
        const instance = new dlg({
            vuetify: Vue.prototype.tiptapVuetifyPlugin.vuetify,
            propsData: {
                ctx: c
            }
        });

        instance.$mount();
        document.querySelector('body').appendChild(instance.$el)
    }
});
