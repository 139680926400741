<template>
    <dashboard-layout-form>
        <template slot="actions">
            <v-btn color="primary" class="rounded-0" @click="submit()" v-if="this.$route.params.id">Updaten</v-btn>
            <v-btn color="primary" class="rounded-0" @click="submit()" v-else>Opslaan</v-btn>
        </template>
        <v-container>
            <v-row>
                <v-col cols="8">
                    <v-card class="pa-8">
                        <v-row>
                            <v-col cols="12">
                                <v-card-title class="pa-0 ma-0">Mail planner</v-card-title>
                            </v-col>
                        </v-row>
                        <v-form>
                            <v-row>
                                <v-col cols="12" class="mt-4 mb-0">
                                    <v-text-field
                                        v-model="naam"
                                        :counter="50"
                                        label="E-mail onderwerp"
                                        outlined
                                        clearable
                                        required
                                        dense
                                        single-line
                                        :rules="[v => !!v || 'Dit veld is verplicht']"
                                    ></v-text-field>
                                    <span class="error--text mt-0">Let op! De naam van de batch staat gelijk aan de titel van de e-mails die gaan worden verzonden!</span>
                                </v-col>

                                <v-col cols="12">
                                    <v-select
                                        v-model="mail_template_id"
                                        :items="templates"
                                        item-text="naam"
                                        item-value="id"
                                        single-line
                                        label="Kies een template"
                                        dense
                                        outlined
                                        clearable
                                        required
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8">
                                    <v-date-picker
                                        v-model="datum"
                                        :show-current="true"
                                        :min="dateNow.toString()"
                                        full-width
                                    ></v-date-picker>
                                </v-col>
                                <v-col cols="4" class="text-right">
                                    <v-time-picker
                                        v-model="time"
                                        format="24hr"
                                        scrollable
                                    ></v-time-picker>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-col>

                <v-col cols="4">
                    <v-card class="h-100">
                        <v-card-text>
                            <v-card-title class="pa-0 pb-2 ma-0">E-mail Bijlage</v-card-title>
                            <template v-if="this.$route.params.id">
                                <span class="error--text">Let op! Bij het aanpassen moet je de file opnieuw uploaden!!!</span>
                            </template>
                            <template>
                                <v-file-input
                                    v-model="media"
                                    label="Bijlage toevoegen"
                                    filled
                                    show-size
                                    dense
                                    prepend-icon="mdi-paperclip"
                                ></v-file-input>
                            </template>
                            <template v-if="this.$route.params.id">
                                <span>Gebruikte Bijlage: {{mediaName}}</span>
                                <v-divider></v-divider>
                                <span>Opslag-locatie: {{mediaPath}}</span>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </dashboard-layout-form>
</template>

<script>

export default {
    name: 'MailPlanner',
    data () {
        return {

            naam: '',
            time: '05:00',
            media: [],
            mediaPath: '',
            mediaName: '',
            datum: "",
            dateNow: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            templates: [],
            mail_template_id: '',
        }
    },
    methods: {
        async submit() {
            this.loading = true;
            if (this.$route.params.id){
                // Edit Mailplanner
                try {
                    let formData = new FormData();
                    formData.append('_method', 'PATCH');
                    formData.append('naam', this.naam);
                    formData.append('datum', this.datum);
                    formData.append('time', this.time);
                    formData.append('media', this.media);
                    formData.append('mail_template_id', this.mail_template_id);

                    await this.$axios.post(`planners/${this.$route.params.id}`, formData);

                    await this.$router.push({name: 'Planners'});

                } catch(e) {
                    if (e.response.status === 422) {
                        if (e.response.data.error) {
                            await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                        }
                    }
                } finally {
                    this.loading = false;
                }
            }else {
                // New MailPlanner
                try {
                    let formData = new FormData();
                    formData.append('naam', this.naam);
                    formData.append('datum', this.datum);
                    formData.append('time', this.time);
                    formData.append('media', this.media);
                    formData.append('mail_template_id', this.mail_template_id);

                    await this.$axios.post('planners', formData);

                    await this.$router.push({name: 'Planners'});

                } catch(e) {
                    if (e.response.status === 422) {
                        if (e.response.data.error) {
                            await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                        }
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async load(id) {
            let resp = await window.axios.get(`planners/${id}`);
            let data = resp.data.data;

            this.naam = data.naam;
            this.datum = data.datum;
            this.time = data.time;
            this.mediaPath = data.mediaPath;
            this.mediaName = data.mediaName;
            this.mail_template_id = data.mail_template_id;
        }
    },
    async mounted() {
        if (this.$route.params.id)
            await this.load(this.$route.params.id);

        if (this.datum == "") {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            this.datum = (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }

        // On mount -> grab all the mailtemplates, filter the id and name and put the objects in an array
        let resp = await window.axios.get(`templatelists`);
        this.templates = resp.data.data;

    }
}
</script>
