<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="templates" class="elevation-1"
                              :options.sync="pagination" :server-items-length="totalItems"
                              :footer-props="{itemsPerPage: rowsPerPage, page}" dense >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Mail Templates</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                :to="{name: 'NewTemplate', params:'new'}"
                            >
                                <v-icon>mdi-plus</v-icon> Nieuw
                            </v-btn>
                        </v-toolbar>
                        <v-dialog
                            v-model="dialog"
                            max-width="640px"
                        >
                            <v-card>
                                <v-card-title>Verwijder Mail Template</v-card-title>
                                <v-card-text>Weet je zeker dat je de template wilt verwijderen?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="dialog = false">Annuleer</v-btn>
                                    <v-btn text color="primary" @click="deleteConfirm">Verwijder</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon small @click="$router.push({name: 'EditTemplate', params: { id: item.id }})">mdi-pencil</v-icon>
                        <v-icon small @click="showDeleteDialog(item.id)">mdi-delete</v-icon>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Templates",
    data() {
        return {
            loading: false,
            dialog: false,
            deleteItem: null,
            headers: [
                {text: "id", value: "id"},
                {text: "naam template", value: "naam"},
                {text: "Aangemaakt op", value: "created_at"},
                {text: "Laatst aangepast", value: "updated_at"},
                {text: "Acties", value: "actions"},
            ],
            page: 1
        }
    },
    methods: {
        async showDeleteDialog(id) {
            this.deleteItem = id;
            this.dialog = true;
        },
        async deleteConfirm() {
            try {
                await this.$axios.delete(`templates/${this.deleteItem}`, {_method: 'delete'});
                await this.$store.dispatch('templates/getTemplates', {page: this.page})
            } finally {
                this.dialog = false;
            }
        }
    },

    computed: {
        ...mapGetters({
            totalItems: 'templates/totalItems',
            rowsPerPage: 'templates/rowsPerPage',
            templates: 'templates/templates'
        }),
        pagination: {
            get:function() {
                return this.$store.getters['templates/pagination'];
            },
            set:function(value){
                this.loading = true;
                this.$store.dispatch('templates/getTemplates',{page: value}).then(() => this.loading = false);
            }
        },
    },
    watch: {
        pagination: {
            handler () {
                this.loading = true
                this.$store.dispatch('templates/getTemplates',{page: this.page})
                    .then(() => {
                        this.loading = false
                    })
            },
            update(){
                this.loading = true
                this.$store.dispatch('templates/getTemplates',{page: this.page})
                    .then(() => {
                        this.loading = false
                    })
            },
            deep: true
        }
    },
}
</script>

<style scoped>

</style>
