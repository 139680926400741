<template>
    <dashboard-layout-form>
        <template slot="actions">
            <v-btn color="primary" class="rounded-0" @click="submit()" v-if="this.$route.params.id">Updaten</v-btn>
            <v-btn color="primary" class="rounded-0" @click="submit()" v-else>Opslaan</v-btn>
        </template>
        <v-container>
            <v-card class="pa-8">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="pa-0 ma-0">Email Templates</v-card-title>
                        <v-card-subtitle class="pa-0 ma-0">
                            Hier kunt u instellen wat voor text er in de e-mailbody komt te staan. Alleen wat hier wordt ingevoerd zal in de email komen te staan!
                        </v-card-subtitle>
                    </v-col>
                </v-row>
                <v-form>
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" class="mt-4">
                                    <v-text-field
                                        v-model="naam"
                                        :counter="50"
                                        label="Naam Template: "
                                        outlined
                                        clearable
                                        required
                                        :rules="[v => !!v || 'Dit veld is verplicht']"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-card rounded="1" outlined>
                                        <v-card-title>E-mail Opmaak</v-card-title>
                                        <v-divider></v-divider>
                                        <content-editor class="pt-2" v-model="content"></content-editor>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-container>
    </dashboard-layout-form>
</template>

<script>
import ContentEditor from "../../../components/ContentEditor";

export default {
    name: "NewTemplates.vue",
    components: {ContentEditor },
    data () {
        return {
            naam: '',
            content: '',
        }
    },

    methods: {
        async submit() {
            this.loading = true;

            if (this.$route.params.id){
                try {
                    // Edit Template
                    await this.$axios.post(`templates/${this.$route.params.id}`, {
                        _method: 'PATCH',
                        naam: this.naam,
                        content: this.content,
                    });
                    await this.$router.push({name: 'Templates'});

                } catch(e) {
                    if (e.response.status === 422) {
                        if (e.response.data.error) {
                            await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                        }
                    }
                } finally {
                    this.loading = false;
                }
            }else {
                // New Template
                try {
                    await this.$axios.post('templates', {
                        naam: this.naam,
                        content: this.content,
                    });
                    await this.$router.push({name: 'Templates'});

                } catch(e) {
                    if (e.response.status === 422) {
                        if (e.response.data.error) {
                            await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                        }
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async load(id) {
            let resp = await window.axios.get(`templates/${id}`);
            let data = resp.data.data;

            this.naam = data.naam;
            this.content = data.content;
        }
    },
    async mounted() {
        if (this.$route.params.id)
            await this.load(this.$route.params.id);
    }
}
</script>

