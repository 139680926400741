<template>
    <v-card flat>
        <v-tabs height="30" v-model="tabEditor" class="mb-3">
            <v-tab>Smart editor</v-tab>
            <v-tab>Text editor</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabEditor">
            <v-tab-item>
                <tiptap-vuetify
                    :value="value"
                    @input="$emit('input', $event)"
                    :extensions="extensions"
                />
            </v-tab-item>
            <v-tab-item>
                <v-textarea outlined
                            :value="value"
                            @input="$emit('input', $event)"
                            class="rounded-0"
                >
                </v-textarea>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';

import UploadButton from "../utils/UploadButton";
export default {
    name: "ContentEditor",
    props: ['value'],
    components: {TiptapVuetify},
    data: () => ({
        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak,
            UploadButton,
        ],
        tabEditor: 0,
    }),
}
</script>

<style scoped>

</style>
