<template>
    <dashboard-layout>
        <v-row>
            <v-col cols="4">
                <v-card style="height: 100%">
                    <v-card-title> PowerMailer</v-card-title>
                    <v-card-subtitle>powered by Pro-Digitaal</v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card style="height: 100%" flat>
                    <v-card-text>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {}
    },
}
</script>
