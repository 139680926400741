<template>
    <v-app>
        <v-app-bar app dense clipped-left>
            <v-toolbar-title>
                <v-img
                    contain
                    src="../assets/logo.png"
                    max-width="200"
                ></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
        </v-app-bar>

        <v-navigation-drawer
            permanent
            app
            clipped
        >
            <template v-slot:prepend>
                <v-list-item two-line>

                    <v-list-item-content>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                        <v-list-item-subtitle class="green--text font-weight-bold">Logged In</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <v-divider></v-divider>

            <v-list
                dense

            >
                <v-subheader class="font-weight-bold">Power-CMS</v-subheader>
                <v-list-item
                    v-for="item in nav"
                    :key="item.text"
                    link
                    :to="item.to"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list
                dense
            >
                <v-subheader class="font-weight-bold">Beheer</v-subheader>
                <v-list-item
                    v-for="item in beheernav"
                    :key="item.text"
                    link
                    :to="item.to"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>


                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Uitloggen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-container>
                <slot></slot>
            </v-container>
        </v-main>

    </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DashboardLayout.vue",
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        }),
    },
    data() {
        return {
            nav: [
                {icon: 'mdi-account-tie', text: 'Systeem Gebruikers', to: {name: 'Users'}},
                {icon: 'mdi-account-multiple', text: 'Inschrijvers', to: {name: 'Inschrijvers', params: { type: "overview"}}},
                {icon: 'mdi-application-edit-outline', text: 'Email Templates', to: {name: 'Templates'}},
                {icon: 'mdi-notebook-edit-outline', text: 'Powermail Plannen', to: {name: 'Planners'}},

            ],
            beheernav: [
                {icon: 'mdi-clipboard-check', text: 'Geslaagde Batches', to: {name: 'Batches'}},
            ]
        }
    }
}
</script>

<style scoped>

</style>
