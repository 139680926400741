export default {
    getInschrijvers (context, {page, search} ){
        let page_number = page.page || context.state.pagination.page;
        let rowsPerPage = page.itemsPerPage || context.state.pagination.rowsPerPage;

        window.axios.get(`inschrijvers`, {
            params: {
                page: page_number,
                rowsPerPage,
                search: search === "" ? undefined : search
            }
        })
            .then((response) => {
                context.commit('updateInschrijvers', response.data);
            });
    },
}
