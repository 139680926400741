<template>
    <dashboard-layout>
        <v-row>

            <v-col cols="12" md="4">
                <h1>E-mailinstellingen</h1>
                <v-form>
                    <v-text-field label="Naam afzender" v-model="settings.from_name" hide-details></v-text-field>
                    <!-- <v-text-field label="E-mailadres afzender" v-model="settings.from_address" hide-details></v-text-field> -->
                    <v-text-field label="E-mailadres Administrator" v-model="settings.admin_address" hide-details></v-text-field>
                    <v-text-field label="Uitschrijflink" v-model="settings.unsubscribe_link" hide-details></v-text-field>
                    <h1 class="overline">SMTP Instellingen</h1>
                    <v-text-field label="SMTP host" v-model="settings.smtp_host" hide-details></v-text-field>
                    <v-text-field label="SMTP port" v-model="settings.smtp_port" hide-details type="number"></v-text-field>
                    <v-text-field label="Authenticatie e-mail" v-model="settings.smtp_email" hide-details></v-text-field>
                    <v-text-field label="Wachtwoord" type="password" v-model="settings.smtp_password" hide-details></v-text-field>
                    <v-checkbox label="SSL?" v-model="settings.smtp_ssl"></v-checkbox>
                    <div class="text-right">
                        <v-btn @click="sendTestMail">Test</v-btn>
                        <v-btn color="primary" @click="save">Opslaan</v-btn>
                    </div>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card v-if="testResp" flat>
                    <v-alert color="error" dense v-if="!testResp.success">Er is iets foutgegaan tijdens het versturen van de testmail. Zie hieronder voor details.</v-alert>
                    <v-alert color="success" dark dense v-else>De test e-mail is met succes verstuud. Zie hieronder voor verdere details.</v-alert>
                    <v-textarea outlined :value="testResp.output"></v-textarea>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
export default {
    name: "MailSettings",
    data() {
        return {
            settings: {},
            testResp: null
        }
    },
    methods: {
        async save() {
            await this.$axios.post('/settings/mail', this.settings);
        },
        async sendTestMail() {
            const resp = await this.$axios.get('/settings/mail/test');
            this.testResp = resp.data;
        }
    },
    async mounted() {
        const resp = await this.$axios.get('/settings/mail');
        this.settings = resp.data.data;
    },

}
</script>

<style scoped>

</style>
