export default {
    getTemplates (context, {page} ){
        let page_number = page.page || context.state.pagination.page;
        let rowsPerPage = page.itemsPerPage || context.state.pagination.rowsPerPage;

        window.axios.get(`templates?page=${page_number}&rowsPerPage=${rowsPerPage}`)
            .then((response) => {
                context.commit('updateTemplates', response.data);
            });
    },
}
