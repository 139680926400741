import Vue from 'vue'
import Vuex from 'vuex'

import auth from './module-auth'
import users from './module-user'
import inschrijvers from './module-inschrijvers'
import templates from './module-mailtemplate'
import planners from './module-mailplanner'
import batches from './module-batch'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
      auth,
      users,
      inschrijvers,
      templates,
      planners,
      batches,
  }
})
