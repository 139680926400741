<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="templates" class="elevation-1"
                              :options.sync="pagination" :server-items-length="totalItems"
                              :footer-props="{itemsPerPage: rowsPerPage, page}" dense >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Verzonden E-mails</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="reload"
                            >
                                <v-icon>mdi-reload</v-icon> Reload
                            </v-btn>
                        </v-toolbar>
                        <v-dialog
                            v-model="dialog"
                            max-width="640px"
                        >
                            <v-card>
                                <v-card-title>Verwijder Batch log</v-card-title>
                                <v-card-text>Weet je zeker dat je de Batch wilt verwijderen?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="dialog = false">Annuleer</v-btn>
                                    <v-btn text color="primary" @click="deleteConfirm">Verwijder</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:item.temps="{item}">
                        <router-link class="text-decoration-none" :to="{ name: 'EditTemplate', params: { id: item.template_id } }"> {{item.template_naam}}</router-link>
                    </template>
                    <template v-slot:item.attatch="{item}">
                        <a v-if="item.bijlage" class="text-decoration-none" target="_blank" :href="item.bijlage">{{item.bijlage_naam}}</a>
                        <span v-else>{{item.bijlage_naam}}</span>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon small @click="showDeleteDialog(item.id)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "MailPlanner",
    data() {
        return {
            loading: false,
            dialog: false,
            deleteItem: null,
            headers: [
                {text: "id", value: "id"},
                {text: "naam", value: "naam"},

                {text: "Email Template", value: "temps"},
                {text: "Bijlage", value: "attatch"},

                {text: "Aangemaakt op", value: "created_at"},
                {text: "Afgerond op", value: "deleted_at"},
                {text: "Verwijder", value: "actions"},
            ],
            page: 1
        }
    },
    methods: {
        async reload (){
            this.loading = true
            await this.$store.dispatch('batches/getBatches',{page: this.page})
                .then(() => {
                    this.loading = false
                })
        },
        async showDeleteDialog(id) {
            this.deleteItem = id;
            this.dialog = true;
        },
        async deleteConfirm() {
            try {
                await this.$axios.delete(`batchdelete/${this.deleteItem}`, {_method: 'delete'});
                this.loading = true;
            } finally {
                this.dialog = false;
                await this.$store.dispatch('batches/getBatches', {page: this.page});
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapGetters({
            totalItems: 'batches/totalItems',
            rowsPerPage: 'batches/rowsPerPage',
            templates: 'batches/batches'
        }),
        pagination: {
            get:function() {
                return this.$store.getters['batches/pagination'];
            },
            set:function(value){
                this.loading = true;
                this.$store.dispatch('batches/getBatches',{page: value}).then(() => this.loading = false);
            }
        },
    },
    watch: {
        pagination: {
            handler () {
                this.loading = true
                this.$store.dispatch('batches/getBatches',{page: this.page})
                    .then(() => {
                        this.loading = false
                    })
            },
            update(){
                this.loading = true
                this.$store.dispatch('batches/getBatches',{page: this.page})
                    .then(() => {
                        this.loading = false
                    })
            },
            deep: true
        }
    },
}
</script>

<style scoped>

</style>
