export default {
    getBatches (context, {page} ){
        let page_number = page.page || context.state.pagination.page;
        let rowsPerPage = page.itemsPerPage || context.state.pagination.rowsPerPage;

        window.axios.get(`batches?page=${page_number}&rowsPerPage=${rowsPerPage}`)
            .then((response) => {
                context.commit('updateBatches', response.data);
            });
    },
}
