<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="templates" class="elevation-1"
                              :options.sync="pagination" :server-items-length="totalItems"
                              :footer-props="{itemsPerPage: rowsPerPage, page}" dense >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Mail Planner</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                :to="{name: 'NewPlanner', params:'new'}"
                            >
                                <v-icon>mdi-plus</v-icon> Nieuw
                            </v-btn>
                        </v-toolbar>
                        <v-dialog
                            v-model="dialog"
                            max-width="640px"
                        >
                            <v-card>
                                <v-card-title>Verwijder geplande email</v-card-title>
                                <v-card-text>Weet je zeker dat je de geplande e-mail wilt verwijderen?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="dialog = false">Annuleer</v-btn>
                                    <v-btn text color="primary" @click="deleteConfirm">Verwijder</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:item.attatch="{item}">
                        <a v-if="item.bijlage" class="text-decoration-none" target="_blank" :href="item.bijlage">{{item.bijlage_naam}}</a>
                        <span v-else>{{item.bijlage_naam}}</span>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon small @click="$router.push({name: 'EditPlanner', params: { id: item.id }})">mdi-pencil</v-icon>
                        <v-icon small @click="showDeleteDialog(item.id)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "MailPlanner",
    data() {
        return {
            loading: false,
            dialog: false,
            deleteItem: null,
            headers: [
                {text: "id", value: "id"},
                {text: "Onderwerp", value: "naam"},

                {text: "Email Gepland op", value: "datum"},
                {text: "Status", value: "status"},

                {text: "Bijlage", value: "attatch"},
                {text: "Acties", value: "actions"},
            ],
            page: 1
        }
    },
    methods: {
        async showDeleteDialog(id) {
            this.deleteItem = id;
            this.dialog = true;
        },
        async deleteConfirm() {
            try {
                await this.$axios.delete(`planners/${this.deleteItem}`, {_method: 'delete'});
                await this.$store.dispatch('planners/getPlanners', {page: this.page})
            } finally {
                this.dialog = false;
            }
        }
    },

    computed: {
        ...mapGetters({
            totalItems: 'planners/totalItems',
            rowsPerPage: 'planners/rowsPerPage',
            templates: 'planners/planners'
        }),
        pagination: {
            get:function() {
                return this.$store.getters['planners/pagination'];
            },
            set:function(value){
                this.loading = true;
                this.$store.dispatch('planners/getPlanners',{page: value}).then(() => this.loading = false);
            }
        },
    },
    watch: {
        pagination: {
            handler () {
                this.loading = true
                this.$store.dispatch('planners/getPlanners',{page: this.page})
                    .then(() => {
                        this.loading = false
                    })
            },
            update(){
                this.loading = true
                this.$store.dispatch('planners/getPlanners',{page: this.page})
                    .then(() => {
                        this.loading = false
                    })
            },
            deep: true
        }
    },
}
</script>

<style scoped>

</style>
