<template>
    <div class="clock">
        <div class="clock__hours" v-if="isHour">
            <span v-text="hours"></span>
        </div>
        <div class="clock__minutes" v-text="minutes" v-if="isMinute"></div>
        <div class="clock__seconds" v-text="seconds" v-if="isSecond"></div>
    </div>
</template>

<script>
export default {
    name: "ClockComponent",
    methods: {
        getZeroPad (n) {
            return (parseInt(n, 10) >= 10 ? '' : '0') + n
        },
        updateDateTime() {
            const now = new Date();
            this.hours = now.getHours();
            this.minutes = this.getZeroPad(now.getMinutes());
            this.seconds = this.getZeroPad(now.getSeconds());
            //this.hours = this.hours % 12 || 12;
            this.hours = (this.hours < 10) ? "0" + this.hours : this.hours;
            this.$options.timer = window.setTimeout(this.updateDateTime, 1000);
        },
    },
    props: {
        isHour: {
            type: Boolean,
            default: true
        },
        isMinute: {
            type: Boolean,
            default: true
        },
        isSecond: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
        }
    },
    mounted() {
        //const timer = window.setTimeout(this.updateDateTime, 1000);
        //this.$on('hook:destroyed', () => window.clearTimeout(timer))
        window.setTimeout(this.updateDateTime, 1000);
    }
}
</script>

<style scoped>
.clock {
    background: none !important;
    border: 0.3rem solid #fff;
    border-radius: 0.5rem;
    display: inline-block;
}

.clock__hours,
.clock__minutes,
.clock__seconds {
    display: inline-block;
    font-weight: 400;
    padding: 0.5rem 1rem;
    text-align: center;
    position: relative;
}

.clock__hours {
    border-right: 0.15rem solid #fff;
}

.clock__minutes {
    border-right: 0.15rem solid #fff;
}
</style>
